<template>
  <div class="zxfwxq">
    <div class="header">
      <div class="h1">专家咨询</div>
      <div class="h2">专家把脉  共创辉煌</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/zjzxlist' }">专家列表</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/zjzxxq' }">查看详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="bottom">
        <div class="flex lists">
          <div class="img">
            <el-avatar :size="150" :src="datas.avatar"></el-avatar>
          </div>
          <div class="flex-1">
            <div class="title">{{datas.name}}</div>
            <div class="neirong"></div>
            <div class="neirong">擅长方向： {{datas.major}}</div>
            <div class="neirong col1"><i class="dingwei el-icon-location"></i>{{datas.province}}{{datas.city}}{{datas.area}}{{datas.address}}</div>
          </div>
          <div>
          </div>
        </div>
        <div>
          <div class="zjjs">专家介绍</div>
          <div class="people" v-html="datas.content">

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {expertdetail} from "../../request/moudle/zjzx";

export default {
  name: "gonggaoxq",
  data(){
    return {
      datas:''
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    go(){
      this.$router.push({
        path:'/zxfwlist'
      })
    },
    loadxq(){
      this.$api.zjzx.expertdetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .zxfwxq{
     background: #F5F5F5;
    .header{
      height: 180px;
      background: url("../../assets/gxjy/gxjy.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: 500;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .bottom{
        margin-top: 25px;
        background: #fff;
        padding: 25px;
        .lists{
          padding: 25px;
          padding-bottom: 50px;
          background: #fff;
          border-bottom: 1px solid #eeeeee;
          .btn{
            margin-top: 55px;
            margin-left: 40px;
          }
          .img{
            width: 150px;
            height: 150px;
            margin-right: 35px;
          }
          .title{
            font-size: 18px;
            font-weight: 520;
            line-height: 50px;
          }
          .neirong{
            color: #292929;
            line-height: 36px;
          }
          .dingwei{
            color: red;
            font-size: 25px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
        .zjjs{
            font-size: 16px;
          font-weight: 550;
          line-height: 50px;
          margin-top: 30px;
          padding: 0 30px;
        }
        .people{
          padding: 0 30px;
          line-height: 25px;
        }
      }
    }
  }
</style>